import Flickity from 'flickity';
import imagesLoaded from 'imagesloaded';

class SectionCarousels {
    constructor() {
        this.flktyInstances = [];
    }

    // Initialize the appear functionality
    init() {
        const sectionCarouselEls = document.querySelectorAll('.section-carousel');
        sectionCarouselEls.forEach((el) => {
            const carousel = el.querySelector('.flickity-carousel');

            imagesLoaded(carousel, () => {
                const carouselCounter = el.querySelector('.carousel-counter');
                const carouselButtonPrev = el.querySelector('.carousel-button-prev');
                const carouselButtonNext = el.querySelector('.carousel-button-next');

                // Define a function to set the groupCells option based on window width
                function getGroupCells() {
                    if (window.innerWidth < 768) {
                        return 1; // 1 item per group for screens smaller than 768px
                    } else {
                        return 3; // 3 items per group for larger screens
                    }
                }

                let flkty = new Flickity(carousel, {
                    cellAlign: 'left',
                    groupCells: true,
                    pageDots: false,
                    prevNextButtons: false,
                    wrapAround: true,
                });

                // Store the instance for later reinitialization
                this.flktyInstances.push(flkty);

                // Function to reinitialize Flickity when the window is resized
                function updateFlickity() {
                    //flkty.options.groupCells = getGroupCells();
                    flkty.reloadCells(); // Refresh cells in Flickity
                    flkty.resize(); // Resize the carousel to apply changes
                }

                // Attach event listener for window resize
                window.addEventListener('resize', updateFlickity);

                // Previous button click event
                carouselButtonPrev.addEventListener('click', () => {
                    flkty.previous();
                });

                // Next button click event
                carouselButtonNext.addEventListener('click', () => {
                    flkty.next();
                });

                // Update counter on slide change
                flkty.on('change', updateSlideCounter);

                // Update the slide counter
                function updateSlideCounter() {
                    let currentSlide = flkty.selectedIndex + 1;
                    carouselCounter.innerText = currentSlide;
                }

                // Initial counter update
                updateSlideCounter();
            });
        });
    }
    // Reinitialize all carousels
    reinit() {
        this.flktyInstances.forEach(flkty => {
            flkty.reloadCells(); // Refresh cells
            flkty.resize(); // Resize carousel
        });
    }
}

export default new SectionCarousels();
