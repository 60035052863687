class BackButtons {
    constructor() {
    }

    // Initialize the appear functionality
    init() {
        const backBtnEls = document.querySelectorAll('.back-btn');
        backBtnEls.forEach((el) => {
            const prevUrl = window.APP.helpers.PreviousUrl;
            const currentUrl = window.location.href;

            if (prevUrl && this.isSameSection(prevUrl, currentUrl)) {
                // Perform the action
                console.log('Same section, perform the action');
                el.setAttribute('href', prevUrl);
            } else {
                // Do nothing
                console.log('Different section, no action');
            }
        });
    }

    isSameSection(prevUrl, currentUrl) {
        // Helper function to remove query params and pagination segments
        function cleanUrl(url) {
            // Remove query parameters
            url = url.split('?')[0];

            // Remove pagination segments like /p2
            url = url.replace(/\/p\d+$/, '');

            return url;
        }

        // Clean both URLs
        const cleanedPrevUrl = cleanUrl(new URL(prevUrl, window.location.origin).pathname);
        const cleanedCurrentUrl = cleanUrl(new URL(currentUrl, window.location.origin).pathname);

        // Split URLs into segments
        const prevSegments = cleanedPrevUrl.split('/').filter(Boolean);
        const currentSegments = cleanedCurrentUrl.split('/').filter(Boolean);

        // Compare the first segments (assuming the first segment defines the section)
        return prevSegments.length > 0 && prevSegments[0] === currentSegments[0];
    }
}

export default new BackButtons();
