class HeaderHeightCalc {
	constructor() {
        this.calulate();

        document.addEventListener('DOMContentLoaded', e => {
            this.calulate();
        });

        // We listen to the resize event
        window.addEventListener('load', () => {
            this.calulate();
        });

        // We listen to the resize event
        window.addEventListener('resize', () => {
            this.calulate();
        });
	}
    calulate() {
        const header = document.querySelector('.mn-hdr');

        if (header) {
            // Get the header element height
            let hh = header.offsetHeight;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--hh', `${hh}px`);
        }
    }
}

export default new HeaderHeightCalc();
