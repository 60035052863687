// Imports
import MicroModal from "micromodal";
import { lock, unlock } from 'tua-body-scroll-lock';

class Modal {
    constructor() {}

    init() {
        MicroModal.init({
            openClass: '_open', // Custom class to be applied when modal is open
            openTrigger: 'data-modal-open', // Custom data attribute to open modal
            closeTrigger: 'data-modal-close', // Custom data attribute to close modal
            onShow: function(modal) {
                // Disable scrolling
                lock(modal);

                let iframe = modal.querySelector('iframe');
                let videoSettings = iframe.getAttribute('data-video');

                // Split the videoSettings string by a comma
                let [videoType, videoID] = videoSettings.split(',');
                let videoUrl = '';

                if (videoType === 'vimeo') {
                    videoUrl = `https://player.vimeo.com/video/${videoID}?title=0&byline=0&portrait=0&controls=1&autoplay=1`;
                }
                else if (videoType === 'youtube') {
                    videoUrl = `https://www.youtube.com/embed/${videoID}?showinfo=0&rel=0&iv_load_policy=3&start=0&controls=1&autoplay=1`;
                }

                // Add URL to iframe
                iframe.src = videoUrl;
            },
            onClose: function(modal) {
                let iframe = modal.querySelector('iframe');
                iframe.src = ''; // Stop the video by resetting the src

                // Enable scrolling
                unlock(modal);
            }
        });
    }
}

export default new Modal();
