/* Imports */
import { Formie } from '../../../vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib';

class Forms {
    constructor() {
        this.attr = 'data-formie';
        this.FormieInstance = new Formie();
    }

    init() {
        this.FormieInstance.initForms(false);
    }
    destroyForms() {
        if (this.FormieInstance) {
            let formEls = document.querySelectorAll(`[${this.attr}]`);

            formEls.forEach((form) => {
                this.FormieInstance.destroyForm(form);
            });
        }
    }
}

export default new Forms();
