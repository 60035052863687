import barba from '@barba/core';

class EntryFilters {
    constructor() {

    }

    init() {
        this.attrName = 'data-filters';
        this.container = document.querySelector(`[${this.attrName}]`);
        if (!this.container) return;

        this.baseUrl = this.container.getAttribute(this.attrName);
        this.selectElements = this.container.querySelectorAll('select');

        this.addEventListeners();
    }

    addEventListeners() {
        this.selectElements.forEach(select => {
            select.addEventListener('change', this.handleFilterChange.bind(this));
        });
    }

    handleFilterChange() {
        const params = Array.from(this.selectElements)
            .filter(select => select.value)  // Only include selects with a value
            .map(select => `${encodeURIComponent(select.name)}=${encodeURIComponent(select.value)}`)
            .join('&');

        const url = params ? `${this.baseUrl}?${params}` : this.baseUrl;

        // Redirect to the constructed URL
        barba.go(url);
        //window.location.href = url;
    }
}
export default new EntryFilters();
