class ExternalLinks {
    constructor() {}

    build() {
        let links = document.querySelectorAll('a[href]');
        const internalProtocols = ['tel:', 'mailto:', 'sms:', 'callto:'];

        for (let i = 0; i < links.length; i++) {
            let link = links[i];
            let href = link.getAttribute('href');

            // Check if the link is using one of the internal protocols
            let isInternalProtocol = internalProtocols.some(protocol => href.startsWith(protocol));

            if (!isInternalProtocol && link.hostname != location.hostname) {
                link.setAttribute('data-external', '');
            }
        }
    }
}

export default new ExternalLinks();
